<template>
  <MosaicUiButtonsPrimaryButton
    full-rounded
    outline
    :class="contentClass"
    theme="white"
    @click="onButtonClicked"
  >
    <span class="space-x-2 flex flex-row items-center justify-center">
      <slot><SendMessageIcon /> <span>{{ t('marketplace.messages.sendMessage') }}</span></slot>
    </span>
  </MosaicUiButtonsPrimaryButton>

  <SendMessageModal @sent="onMessageSent" v-model="isSendModalShown" :psychologist-slug="psychologistSlug" />
  <AuthMessageModal v-model="isAuthModalShown" />
</template>

<script setup lang="ts">
import SendMessageIcon from "../../../../../assets/icons/message.svg";
import SendMessageModal from "../components/SendMessageModal.vue";
import AuthMessageModal from "../components/AuthMessageModal.vue";

const {fetchSession, isLoggedIn} = useAuthSession();

const props = defineProps({
  psychologistSlug: {
    type: String,
    required: true,
  },
  contentClass: {
    type: String,
    default: 'w-full',
  }
});
const {t} = useI18n();
const localePath = useLocalePath();
const router = useRouter();
const isSendModalShown = ref(false);
const isAuthModalShown = ref(false);

onMounted(() => {
  if (process.client) {
    if (localStorage.getItem('message-modal')) {
      isSendModalShown.value = true;
      localStorage.removeItem('message-modal');
    }
  }
})

async function onButtonClicked() {
  await fetchSession();
  if (isLoggedIn.value) {
    isSendModalShown.value = true;
  } else {
    isAuthModalShown.value = true;
  }
}

async function onMessageSent(roomId: string) {
  await router.push(localePath(`/cabinet/chat/${roomId}`));
}
</script>

<style scoped>

</style>
